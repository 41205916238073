<template>
  <validation-observer
    ref="instructorSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="instructorSaveModal"
      body-class="position-static"
      centered
      size="lg"
      title="Thêm thành viên"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="employeeIds">
              <template v-slot:label>
                Thành viên <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Thành viên"
                rules="required"
              >
                <v-select
                  id="employeeIds"
                  v-model="employeeIds"
                  label="name"
                  multiple
                  :options="employees"
                  :reduce="option => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('instructorSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BModal, BOverlay, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import { Flag } from '@/const/flag'

export default {
  name: 'TechTransferMemberSave',
  components: {
    BCol,
    BRow,
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    researchStudentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      employeeIds: [],
      required,
    }
  },
  computed: {
    ...mapGetters({
      employees: 'researchInstructor/employees',
    }),
    isCreated() {
      return !this.instructor
    },
  },
  methods: {
    ...mapActions({
      getEmployeesByOrganizationId: 'researchInstructor/getEmployeesByOrganizationId',
      createMember: 'researchInstructor/createResearchInstructor',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isLoading = true
      try {
        await this.getEmployeesByOrganizationId({ organizationId: this.user.orgId })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .instructorSaveFormRef
        .reset()
      this.employeeIds = []
    },
    async onSave(type = null) {
      const valid = this.$refs
        .instructorSaveFormRef
        .validate()
      if (valid) {
        const body = []
        this.employeeIds.forEach(employeeId => {
          body.push({ employeeId, researchStudentId: this.researchStudentId, status: Flag.ACTIVE })
        })
        this.isLoading = true
        try {
          const response = await this.createMember(body)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('instructorSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
